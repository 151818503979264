<template>
  <div>
    <home-navbar></home-navbar>
    <div class="bl_content">
      <div id="beer-slider" class="beer-slider" data-beer-label="before">
        <img class="image" :src="imageDetail.file_url" alt="before image">
        <div class="beer-reveal" data-beer-label="after">
          <img class="image" :src="imageDetail.result_file_url" alt="after image">
        </div>
      </div>
      <div class="bl_image_text">
        <div v-if="imageDetail.staging_request">
          {{imageDetail.staging_request.style.name}}
        </div>
        <div class="bl_link mb-3">
          <a class="mr-3 d-flex align-items-center" :href="'https://twitter.com/share?url='+currentPath" rel="nofollow noopener" target="_blank">
            <img class="social_img" src="/img/icons/social/icon-twitter-circle.png" alt="icon-twitter-circle"/>
          </a>
          <a class="mr-3 d-flex align-items-center" :href="'https://www.facebook.com/share.php?u='+currentPath" rel="nofollow noopener" target="_blank">
            <img class="social_img" src="/img/icons/social/icon-facebook-circle.png" alt="icon-facebook-circle"/>
          </a>
          <button class="btn btn-copy blue white-text" v-clipboard:copy="currentPath" v-clipboard:success="onCopy">Copy URL</button>
        </div>
        <div class="bl_text">
          <div class="font-weight-bold mb-2">{{ imageDetail.title }}</div>
          <div class="mb-2">{{ imageDetail.text }}</div>
          <div>{{ imageDetail.tag }}</div>
        </div>
      </div>
    </div>
    <home-footer></home-footer>
  </div>
</template>
<script src="https://unpkg.com/beerslider/dist/BeerSlider.js"></script>
<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCsWywCbXFr1Kju-h-XEc_46MndAqpBB9I"></script>
<script>
import homeNavbar from '../components/customer/Home-Navbar.vue';
import homeFooter from '../components/customer/Home-Footer.vue';
import { globalService_GetDesignDetail } from '../services/global';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Design Detail',
    };
  },
  components: {
    'home-navbar': homeNavbar,
    'home-footer': homeFooter,
  },
  data() {
    return {
      currentPath: null,
      imageDetail: {},
    };
  },
  created(){
    this.getImageDetail(this.$route.params.id);
    this.currentPath = location.href;
  },
  methods: {
    getImageDetail(id){
      globalService_GetDesignDetail({
        id: id,
      })
      .then((response) => {
        this.imageDetail = response.data.result;
        console.log(this.imageDetail);
        new BeerSlider(document.getElementById("beer-slider"));
      })
      .catch((error) => {
        console.log(error);
      });
    },
    onCopy(){
      this.$toasted.show('URLをコピーしました', );
    }
  }
}
</script>

<style scoped>
  .bl_content{
    display: flex;
    align-items: center;
    margin: 124px auto 50px;
    max-width: 1000px;
    width: 80%;
    height: 500px;
    border-radius: 20px;
    background: #f5f5f5;
  }

  .bl_content .beer-slider{
    width: 60%;
    height: 100%;
  }

  .bl_content .beer-slider .image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .bl_content .beer-slider .beer-reveal .image{
    width: 200%;
  }

  .bl_content .bl_image_text{
    width: 40%;
    margin: 0 20px;
  }

  .bl_content .bl_image_text .bl_link{
    display: flex;
  }

  @media only screen and (max-width: 899px) {
    .bl_content{
      flex-direction: column;
      width: 90%;
      height: 600px;
    }

    .bl_content .beer-slider{
      width: 100%;
      height: 60%;
    }

    .bl_content .beer-slider .image{
      border-top-right-radius: 20px;
      border-bottom-left-radius: 0;
    }

    .bl_content .bl_image_text{
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      margin-top: 20px;
    }

    .bl_content .bl_image_text .bl_text{
      text-align: center;
    }

    .bl_content .bl_image_text .bl_link{
      justify-content: center;
      margin-top: 20px;
    }
  }

  .bl_content .bl_image_text .social_img{
    object-fit: contain;
  }

  .bl_content .bl_image_text .btn-copy{
    width: 100px;
    transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .bl_content .bl_image_text .btn-copy:hover{
    background: #757575;
    color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
</style>